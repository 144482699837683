@import '@styles/variables';
@import '@styles/mixins';

/* desktop */
.carousel-overlay {
  --padding: 20px;
  --video-border-radius: 8px;
  --background-color: rgba(0, 0, 0, 0.9);

  @include mobile-portrait {
    --padding: 0px;
    --video-border-radius: 0px;
    --background-color: #000;

    .carousel-button {
      display: none;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-color);
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-wrapper {
  position: relative;
  height: 100%;
  aspect-ratio: 9 / 16;
  max-width: calc(100vw - var(--padding) * 2);
  max-height: calc((100vw - var(--padding) * 2) * 16 / 9);

  @include mobile-portrait {
    aspect-ratio: unset;
    max-width: unset;
    max-height: unset;
    min-width: 375px;
    width: 100vw;
    height: 100%;
  }
}

.embla {
  width: 100vw; /* extend to edges of viewport when swiping */
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.videos-container {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.player-container {
  height: 100%;
  border-radius: var(--video-border-radius);
  overflow: hidden;
  position: relative;
  background: #202020;
}

.react-player video {
  @include mobile-portrait {
    object-fit: cover;
  }
}

.player-controls {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.player-controls-inner {
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  opacity: 0;
  transition: opacity 500ms ease;
  pointer-events: none;

  &.visible {
    transition: opacity 100ms ease;
    opacity: 1;
    pointer-events: auto;
  }

  div {
    display: flex;
    gap: 80px;
  }

  svg {
    cursor: pointer;
  }
}

.video-thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: filter 0.5s ease;

  &.inactive {
    filter: blur(6px);
    cursor: pointer;
  }
}

.video-overlay-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.player-container video {
  position: relative;
  z-index: 5;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8; //below the overlay, above the player
background: rgba(0, 0, 0, 0.5);
}

.react-player__play-icon {
  z-index: 6;
}

.close-button {
  position: fixed;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 20px;
  z-index: 100;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 2px;
    background-color: white;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.carousel-button {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #f0f0f0;
  position: relative;
  margin: 20px;
  border: 1px solid rgba(2, 122, 100, 1);
  cursor: pointer;

  &:hover {
    background: #ccc;
  }

  &:disabled {
    background: #ccc;
    border: 1px solid #888;
    opacity: 0.5;
    cursor: unset;
  }

  &::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }

  &.back::before {
    transform: rotate(135deg);
    top: 34%;
    left: 40%;
  }

  &.next::before {
    transform: rotate(-45deg);
    top: 34%;
    left: 30%;
  }

  &.back {
    position: absolute;
    top: 50%;
    left: -100px;
    transform: translateY(-50%);
  }

  &.next {
    position: absolute;
    top: 50%;
    right: -100px;
    transform: translateY(-50%);
  }
}

.carousel-player-timeline {
  position: relative;
  top: 20%;
  width: 80%;
  height: 5px;
  background: #ffffff99;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  &-progress {
    height: 100%;
    background: white;
  }

// Invisible hit area
&::before {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
  background: transparent;
}
}
